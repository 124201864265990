<template>
	<div class="page page-agenda">
		<h2 class="is-title is-inverted is-simple">{{ title }}</h2>
		<div class="page-background is-short" :style="{ backgroundImage: backgroundUrl }"></div>
		<div class="container">
			<div class="agenda__speaker">
				<div class="agenda__speaker__profile">
					<div class="image">
						<img v-if="speaker.profile_picture" :src="speaker.profile_picture | imageUrl('small')" />
						<img v-else src="@/assets/placeholder_speaker.jpg" />
					</div>
					<div>
						<h1>{{ speaker.firstName }} {{ speaker.lastName }}</h1>
						<h2>{{ speaker.country }}</h2>
						<VueMarkdown v-if="speaker.bio" :source="speaker.bio" class="agenda__description content" :prerender="prerender" />
					</div>
				</div>

				<h4 v-if="speaker.sessions && speaker.sessions.length > 0">Sessions</h4>
				<div class="agenda__speaker__sessions">
					<AgendaSessionBox v-for="session in speakerSessions" :key="session.id" :session="session" view="speaker" />
				</div>

				<div class="agenda__detail__back">
					<router-link :to="previousPage === 'Speakers' ? { name: 'Speakers' } : { name: 'Agenda' }">
						<span class="icon"><inline-svg :src="require('../assets/back.svg')" width="26"></inline-svg></span>
						<span>Back</span>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import $http from '../utils/http';
	import VueMarkdown from 'vue-markdown-v2';
	import { prerender } from '../utils/markdown';
	import meta from '../utils/meta';
	import AgendaSessionBox from '../components/AgendaSessionBox';
	export default {
		name: 'AgendaSpeaker',
		components: { AgendaSessionBox, VueMarkdown },
		data() {
			return {
				speaker: {},
				title: 'Event programme',
				subtitle: '',
				background: '',
				previousPage: '',
			};
		},
		beforeRouteEnter(to, from, next) {
			$http.all([$http.get('page-event-programme'), $http.get(`/speakers/${to.params.url}`)]).then(
				$http.spread((page, data) => {
					next(vm => {
						vm.setPage(page);
						vm.setData(data);
						vm.previousPage = from.name;
					});
				})
			);
		},
		beforeRouteUpdate(to, from, next) {
			$http.all([$http.get('page-event-programme'), $http.get(`/speakers/${to.params.url}`)]).then(
				$http.spread((page, data) => {
					this.setPage(page);
					this.setData(data);
					next();
				})
			);
		},
		computed: {
			backgroundUrl() {
				return this.background && this.background.url ? `url('${this.$options.filters.imageUrl(this.background)}')` : null;
			},
			speakerSessions() {
				if (this.speaker.sessions && this.speaker.sessions.length > 0) {
					return this.speaker.sessions.filter(e => e.parent === null);
				}
				return [];
			},
		},
		methods: {
			setPage({ data }) {
				if (data.title) {
					this.title = data.title;
				}
				meta.setTitle(this.title);
				this.subtitle = data.subtitle;
				this.background = data.background;
			},
			setData(data) {
				this.speaker = data.data;
			},
			prerender: prerender,
		},
	};
</script>

<style scoped></style>
